#right-column {
  form {
    padding: 1em;
    .form-item {
      margin-bottom: 2em;
    }
    label {
      margin-bottom: .5em;
    }
    .description {
      margin-top: .5em;
    }
  }
}

#artisticawards-braintree-form {
  input[type='submit'] {
    margin-top: 1em;
  }
}

.commerce-add-to-cart {
  .file-widget {
    // Hide the upload button on add to cart forms
    // because it causes issues with the form state.
    input[type="submit"] {
      display: none;
    }
  }
}