.bigimage {
  .threesixty-button {
   float: left;
    .threesixty-icon {
      margin-top: 10px;
      border-radius: 5px;
      border: 1px solid #e9e9e9
    }
  }
}

.container {
  .threesixty {
    overflow: visible;
    .nav_bar {
      top: auto;
      bottom: -47px;
      right: 118px;
    }
  }
}
