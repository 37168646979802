// This file was created by using http://sebastianpontow.de/css2compass/ to convert
// the old css files to sass. Check out git commit aced6bd592a627555c5cc8a2bb6147fc3f2829a2
// to see the old CSS files.

body {
  margin: 0;
  padding: 0;
  background: #eeeded;
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 12px;
  &.page-product-category {
    #product-content {
      background: transparent;
      width: 735px;
      float: none;
      overflow: hidden;
      background: none;
      width: auto;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 5px 5px;
    }
    #wrap-featured-products {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
    }
  }
  &.front #main-content {
    float: left;
    width: 100%;
    margin: 20px 0 0;
  }
  &.node-type-page #product-content .node {
    margin: 20px;
  }
  &.not-front #wrap-featured-products {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 5px 0 #bbb;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
  }
  &.node-type-blog-post #block-system-main {
    padding: 10px;
    h1 {
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
  &.page-search #block-system-main {
    padding: 20px 30px;
  }
}
a {
  color: #993333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.nice-a {
    border-bottom: 1px solid #8a8a8a;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    font-family: "Arial", "Helvetica", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    color: #993333;
    padding: 1px 9px;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 0 #ffffff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 10px;
    background: #e3e3e3;
    background: -moz-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#fefefe),color-stop(100%,#e3e3e3));
    background: -webkit-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -o-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -ms-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    padding: 0;
    &:hover {
      color: #2e292b;
      border-bottom: 1px solid #8a8a8a;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
      text-decoration: none;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 0 #edd759;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 10px;
      background: #dfbd02;
      background: -moz-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffd900),color-stop(100%,#dfbd02));
      background: -webkit-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -o-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -ms-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: linear-gradient(top,#ffd900 0%,#dfbd02 100%);
    }
  }
  &.nice-a2 {
    border-bottom: 1px solid #868686;
    color: #ffffff;
    font-family: "Arial", "Helvetica", "sans-serif";
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    text-align: center;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 -1px 0 #389b2d;
    background: #21a427;
    background: -moz-linear-gradient(top,#52ce3d 0%,#21a427 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#52ce3d),color-stop(100%,#21a427));
    background: -webkit-linear-gradient(top,#52ce3d 0%,#21a427 100%);
    background: -o-linear-gradient(top,#52ce3d 0%,#21a427 100%);
    background: -ms-linear-gradient(top,#52ce3d 0%,#21a427 100%);
    background: linear-gradient(top,#52ce3d 0%,#21a427 100%);
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr = '#52ce3d', endcolorstr = '#21a427', gradienttype = 0);
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 10px;
  }
}
img {
  border: 0;
}
input {
  &:focus {
    outline: none;
  }
  &.nice-i {
    color: #c2c2c2;
    border: 1px solid #e2e2e2;
    padding: 4px 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: inset 2px 4px 4px -1px rgba(229, 229, 229, 1);
  }
  &.nice-s {
    border-bottom: 1px solid #8a8a8a;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    font-family: "Arial", "Helvetica", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    color: #993333;
    padding: 1px 9px;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 0 #ffffff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 10px;
    background: #e3e3e3;
    background: -moz-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#fefefe),color-stop(100%,#e3e3e3));
    background: -webkit-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -o-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -ms-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    &:hover {
      color: #2e292b;
      border-bottom: 1px solid #8a8a8a;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
      text-decoration: none;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 0 #edd759;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 10px;
      background: #dfbd02;
      background: -moz-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffd900),color-stop(100%,#dfbd02));
      background: -webkit-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -o-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -ms-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: linear-gradient(top,#ffd900 0%,#dfbd02 100%);
    }
  }
}
.commerce-add-to-cart {
  .form-submit {
    border-bottom: 1px solid #8a8a8a;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    font-family: "Arial", "Helvetica", "sans-serif";
    font-size: 14px;
    font-weight: normal;
    color: #993333;
    padding: 1px 9px;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 1px 0 #ffffff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 10px;
    background: #e3e3e3;
    background: -moz-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#fefefe),color-stop(100%,#e3e3e3));
    background: -webkit-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -o-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: -ms-linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    background: linear-gradient(top,#fefefe 0%,#e3e3e3 100%);
    float: right;
    margin-top: 12px;
    clear: both;
    &:hover {
      color: #2e292b;
      border-bottom: 1px solid #8a8a8a;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
      text-decoration: none;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 0 #edd759;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 10px;
      background: #dfbd02;
      background: -moz-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffd900),color-stop(100%,#dfbd02));
      background: -webkit-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -o-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: -ms-linear-gradient(top,#ffd900 0%,#dfbd02 100%);
      background: linear-gradient(top,#ffd900 0%,#dfbd02 100%);
    }
  }
  .form-item-quantity {
    padding: 0;
    margin-top: 5px;
    clear: both;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  #edit-line-item-fields {
    float: none;
    label {
      &.collapsed {
        background: url("../images/add-to-cart-collapsed.png") left center no-repeat;
        padding-left: 14px;
      }
      &.expanded {
        background: url("../images/add-to-cart-expanded.png") left center no-repeat;
        padding-left: 14px;
      }
    }
  }
  .wishlist {
    height: auto;
    line-height: normal;
    text-align: center;
    border: none;
    font-family: "Arial", "Tahoma";
    font-size: 12px;
    font-weight: normal;
    color: #993333;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    background-color: transparent;
    background: none;
    &:hover {
      text-decoration: underline;
      height: auto;
      line-height: normal;
      color: #993333;
      border: none;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: none;
      filter: none;
      background: transparent;
    }
  }
}
.clr {
  clear: both;
  float: none;
}
.wrap-title-black {
  height: 40px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px 5px 0 0;
  background: #221e1f;
  background: -moz-linear-gradient(top,#4e4648 0%,#221e1f 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#4e4648),color-stop(100%,#221e1f));
  background: -webkit-linear-gradient(top,#4e4648 0%,#221e1f 100%);
  background: -o-linear-gradient(top,#4e4648 0%,#221e1f 100%);
  background: -ms-linear-gradient(top,#4e4648 0%,#221e1f 100%);
  background: linear-gradient(top,#4e4648 0%,#221e1f 100%);
}
#top {
  width: 100%;
  height: 204px;
  background: #000;
}
#wrap-user-border {
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #383838;
}
#wrap-user-border2 {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #171717;
}
#wrap-user {
  width: 960px;
  height: 35px;
  margin: 0 auto;
  text-align: center;
}
#user-message {
  float: left;
  width: 230px;
  height: 35px;
  line-height: 35px;
  font-size: 11px;
  color: #7e7e7e;
  text-align: left;
}
ul {
  &#user-menu {
    float: left;
    width: 730px;
    height: 35px;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;
    li {
      display: inline-block;
      margin-left: 20px;
      a {
        display: inline-block;
        height: 35px;
        line-height: 35px;
        font-size: 11px;
        color: #c29700;
        text-decoration: none;
        padding-left: 10px;
        background: url("../images/user-menu-arrow.png") no-repeat left center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &#header-menu {
    float: left;
    width: 700px;
    height: 41px;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      float: left;
      height: 41px;
      margin-right: 2px;
      a {
        float: left;
        font-family: "Arial", "Helvetica", "sans-serif";
        font-size: 14px;
        height: 41px;
        line-height: 41px;
        color: #f3f3f3;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0 15px;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 -1px 1px #000000;
        &.active {
          color: #242323;
          //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          text-shadow: 0 0 0 #000000;
          background: #eeeded;
          background: -moz-linear-gradient(top,#ffffff 0%,#eeeded 100%);
          background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffffff),color-stop(100%,#eeeded));
          background: -webkit-linear-gradient(top,#ffffff 0%,#eeeded 100%);
          background: -o-linear-gradient(top,#ffffff 0%,#eeeded 100%);
          background: -ms-linear-gradient(top,#ffffff 0%,#eeeded 100%);
          background: linear-gradient(top,#ffffff 0%,#eeeded 100%);
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 5px 5px 0 0;
        }
        &.special {
          color: #f9d302;
        }
        &.inactive:hover {
          background: #383535;
          background: -moz-linear-gradient(top,#4e484a 0%,#383535 100%);
          background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#4e484a),color-stop(100%,#383535));
          background: -webkit-linear-gradient(top,#4e484a 0%,#383535 100%);
          background: -o-linear-gradient(top,#4e484a 0%,#383535 100%);
          background: -ms-linear-gradient(top,#4e484a 0%,#383535 100%);
          background: linear-gradient(top,#4e484a 0%,#383535 100%);
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
  &#category-menu {
    float: left;
    width: 100%;
    list-style: none;
    padding-bottom: 11px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin: 0;
    background: #2a2a2a;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 5px 5px;
    li {
      float: left;
      width: 100%;
      border-bottom: 1px solid #373737;
      a {
        float: left;
        width: 210px;
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        color: #ffffff;
        font-size: 13px;
        text-decoration: none;
        background: url("../images/cat-menu-arrow.png") no-repeat 210px center;
        &:hover {
          background: #eeb900 url("../images/cat-menu-arrow-hover.png") no-repeat 210px center !important;
          color: #363031 !important;
        }
      }
    }
    ul.subcategory {
      float: left;
      width: 100%;
      padding: 0;
      margin: 0;
      background: #222222;
      list-style: none;
      display: none;
      li {
        float: left;
        width: 100%;
        height: 30px;
        border-bottom: 1px solid #303030;
        a {
          float: left;
          width: 190px;
          height: 30px;
          line-height: 30px;
          padding-left: 40px;
          color: #eeb900 !important;
          font-size: 13px;
          text-decoration: none;
          background: url("../images/cat-menu-arrow.png") no-repeat 210px center !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &.menu {
    float: left;
    width: 100%;
    list-style: none;
    padding-bottom: 11px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin: 0;
    background: #fff;
    li {
      margin: 0;
      float: left;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
      a {
        float: left;
        width: 210px;
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        font-size: 13px;
        text-decoration: none;
        &:hover {
          color: #363031 !important;
        }
      }
    }
    ul.subcategory {
      float: left;
      width: 100%;
      padding: 0;
      margin: 0;
      background: #fff;
      list-style: none;
      display: none;
      li {
        float: left;
        width: 100%;
        height: 30px;
        border-bottom: 1px solid #f0f0f0;
        a {
          float: left;
          width: 190px;
          height: 30px;
          line-height: 30px;
          padding-left: 40px;
          font-size: 13px;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &#cart-summary-list {
    float: left;
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    li {
      float: left;
      width: 210px;
      padding: 5px 10px;
      border-bottom: 1px solid #e5e5e5;
      .image {
        float: left;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        a {
          float: left;
          img {
            float: left;
            width: 50px;
            height: 50px;
            border: 0;
          }
        }
      }
      .info {
        float: left;
        width: 150px;
        height: 50px;
        h4 {
          float: left;
          margin: 0;
          font-weight: normal;
          font-size: 11px;
          a {
            color: #24659c;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .info p {
      float: left;
      margin: 0;
      color: #3a3a3a;
      font-size: 11px;
    }
  }
}
* {
  html {
    ul#user-menu li {
      display: inline;
      a {
        display: inline;
      }
    }
    #block-product-list .prd {
      display: inline;
    }
  }
  +html ul#user-menu li {
    display: inline;
    a {
      display: inline;
    }
  }
  + html #block-product-list .prd {
    display: inline;
  }
}
#header {
  width: 960px;
  height: 144px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
#user_bar {
  list-style: none;
  float: none;
  position: absolute;
  top: 0;
  left: 5px;
  background: #993333;
  color: #cb7777;
  padding: 2px 10px 2px 15px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 4px 4px;
  li {
    float: left;
    padding: 5px;
    padding-top: 3px;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    a {
      color: #fff;
    }
    &.items {
      padding-left: 25px;
      background: url("../images/cart-icon-white2.png") top left no-repeat;
    }
  }
  ul {
    margin-top: 5px;
    margin-left: 0;
    padding-left: 0;
    overflow: hidden;
    margin-bottom: 2px;
  }
}
#header-top {
  float: left;
  width: 100%;
  height: 52px;
  margin: 25px 0;
}
#wrap-logo {
  float: left;
  width: 230px;
  height: 52px;
  a {
    float: left;
    img {
      float: left;
      border: 0;
      margin-left: -4px;
      margin-top: -20px;
    }
  }
}
#wrap-header-info {
  float: right;
  width: 730px;
  height: 52px;
  text-align: left;
}
.price {
  color: #414141;
  float: left;
  font-size: 15px;
  height: 27px;
  line-height: 25px;
  margin: 0 0 0 10px;
}
.category {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 6px 6px 0 0;
  color: #ffffff;
  font-size: 14px;
  height: 30px;
  div {
    .category-wrap {
      padding: 7px;
      .category-text {
        display: inline;
      }
      .field {
        display: inline;
      }
    }
    div.field a {
      color: #24659c;
      font-weight: 700;
      margin: 0 0 0 1px;
    }
  }
}
#cart {
  float: right;
  width: 250px;
  height: 52px;
  padding-left: 70px;
  border-left: 1px dotted #4c4c4c;
  background: url("../images/cart-icon.png") no-repeat 25px center;
}
#cart-title {
  float: left;
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 18px;
  width: 100%;
  color: #ffffff;
  margin-top: 5px;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 0 1px 0 #121212;
  filter: dropshadow(color = #121212, offx = 0, offy = 1);
  a {
    color: #fff;
  }
}
#cart-summarry {
  float: left;
  width: 100%;
  color: #8e8e8e;
  a.items {
    font-weight: bold;
    color: #f3cd02;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  span.price {
    font-weight: bold;
    color: #ffffff;
  }
}
#work-hours {
  float: right;
  width: 250px;
  height: 42px;
  padding-top: 10px;
  padding-left: 65px;
  background: url("../images/working-hours-icon.png") no-repeat 25px center;
  .white-line {
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 13px;
  }
  .pink-line {
    float: left;
    width: 100%;
    color: #8f8f8f;
    font-size: 11px;
  }
}
#header-bottom {
  float: left;
  width: 100%;
  height: 41px;
  border-top: 1px solid #443b3d;
  margin-top: 60px;
  background: #a71e2b;
  background: -moz-linear-gradient(top,#993333 0%,#a71e2b 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#993333),color-stop(100%,#a71e2b));
  background: -webkit-linear-gradient(top,#993333 0%,#a71e2b 100%);
  background: -o-linear-gradient(top,#993333 0%,#a71e2b 100%);
  background: -ms-linear-gradient(top,#993333 0%,#a71e2b 100%);
  background: linear-gradient(top,#993333 0%,#a71e2b 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr = '#993333', endcolorstr = '#a71e2b', gradienttype = 0);
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px 5px 0 0;
}
#search {
  float: right;
  width: 255px;
  height: 36px;
  margin-top: 5px;
  margin-right: 5px;
  text-align: right;
  input {
    &.nice_search {
      float: left;
      width: 200px;
      font-size: 13px;
      height: 28px;
      line-height: 28px;
      margin-left: 16px;
      padding-left: 10px;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      color: #696767;
      background: #fff;
      border-bottom: 1px solid #393637;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px 0 0 5px;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: inset 2px 2px 3px -1px rgba(4, 4, 4, 1);
    }
    &.nice_submit {
      float: left;
      height: 29px;
      width: 29px;
      background: #e0c52c url("../images/search-button.png") no-repeat center center;
      border-bottom: 1px solid #393637;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 5px 5px 0;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: inset 0 1px 3px 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .views-exposed-form {
    .views-submit-button {
      padding: 0;
      input {
        float: left;
        height: 29px;
        width: 29px;
        background: #e0c52c url("../images/search-button.png") no-repeat center center;
        border-bottom: 1px solid #393637;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0 5px 5px 0;
        text-indent: -9999px;
        margin-top: 0;
      }
    }
    .views-widget-filter-search_api_views_fulltext {
      padding-right: 0;
      padding-top: 0;
    }
  }
  .views-widget-filter-search_api_views_fulltext input {
    float: left;
    width: 200px;
    font-size: 13px;
    height: 28px;
    line-height: 28px;
    margin-left: 16px;
    padding-left: 10px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    color: #696767;
    background: #fff;
    border-bottom: 1px solid #393637;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px 0 0 5px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: inset 2px 2px 3px -1px rgba(4, 4, 4, 1);
  }
}
#search-form {
  float: left;
  width: 100%;
}
#wrap-content {
  width: 960px;
  margin: 0 auto;
}
#breadcrumb {
  float: left;
  width: 100%;
  height: 38px;
  line-height: 38px;
  font-size: 11px;
  a {
    text-decoration: none;
    color: #993333;
    &:hover {
      text-decoration: underline;
    }
    &.breadcrumbHome {
      background: url("../images/breadcrumb-home.gif") no-repeat left center;
      padding-left: 25px;
      background-image: url("../images/breadcrumb-home-gray.gif");
    }
  }
  span {
    color: #5f5f5f;
    &.seperator {
      width: 9px;
      margin: 0 5px;
      background: url("../images/breadcrumb-seperator.png") no-repeat center center;
      background-image: url("../images/breadcrumb-seperator-gray.png");
    }
  }
}
#footer {
  float: left;
  width: 100%;
  ul {
    margin-bottom: 8px;
    li {
      margin-left: 5px;
      margin-bottom: 0;
      &.leaf {
        list-style: none none;
      }
      &.expanded {
        list-style: none none;
      }
      &.collapsed {
        list-style: none none;
      }
    }
  }
  .third {
    width: 460px;
  }
  a {
    color: #c29700;
  }
  h3 {
    color: #fff;
    font-size: 1.1em;
  }
  label {
    color: #fff;
    padding-bottom: 3px;
  }
  input {
    background: #ccc;
    border: 1px solid #000;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
  }
  .form-item {
    margin-top: 0;
  }
  .form-required {
    color: #c29700;
  }
  .view-testimonials .quote {
    color: #999;
  }
}
#wrap-footer-links {
  float: left;
  width: 100%;
  margin-top: 40px;
  padding: 30px 0 20px;
  background: #1c1b1b;
  background: -moz-linear-gradient(top,#353535 0%,#1c1b1b 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#353535),color-stop(100%,#1c1b1b));
  background: -webkit-linear-gradient(top,#353535 0%,#1c1b1b 100%);
  background: -o-linear-gradient(top,#353535 0%,#1c1b1b 100%);
  background: -ms-linear-gradient(top,#353535 0%,#1c1b1b 100%);
  background: linear-gradient(top,#353535 0%,#1c1b1b 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr = '#353535', endcolorstr = '#1c1b1b', gradienttype = 0);
}
#footer-links {
  width: 960px;
  margin: 0 auto;
  color: #808080;
}
.wrap-links {
  float: left;
  width: 220px;
  margin-right: 20px;
  h3 {
    float: left;
    width: 100%;
    font-size: 11px;
    color: #818181;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      float: left;
      width: 100%;
      a {
        float: left;
        width: 100%;
        color: #c29700;
        font-size: 11px;
        line-height: 18px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
#wrap-bottom {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #000000;
  font-size: 11px;
  border-top: 1px solid #2c2b2b;
  border-bottom: 2px solid #181818;
}
#bottom {
  width: 960px;
  margin: 0 auto;
  color: #808080;
  .left {
    float: left;
    width: 430px;
    margin: 0;
    padding: 0;
  }
  .right {
    float: right;
    width: 130px;
    height: 20px;
    line-height: 20px;
    text-align: right;
    margin: 10px 0 10px 10px;
    padding: 0;
    p {
      float: left;
      margin: 0;
      padding: 0;
      a {
        float: left;
        margin-left: 5px;
      }
      img {
        float: left;
        border: 0;
      }
    }
  }
  p a {
    color: #808080;
  }
}
#main-content {
  float: left;
  width: 100%;
  .wrap-title-black {
    width: 100%;
    &.round {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
    }
    h2.nice-title {
      float: left;
      width: 270px;
      padding-left: 20px;
      margin: 0;
      &.long {
        width: 210px;
      }
    }
  }
  #left-column {
    .wrap-title-black {
      border-bottom: 1px solid #404040;
    }
    .left-block .wrap-title-black {
      border: 0;
    }
  }
  h1.nice-title {
    float: left;
    width: 500px;
    padding-left: 20px;
    margin: 0;
  }
}
#left-column {
  float: left;
  width: 230px;
  margin-right: 10px;
  .left-block {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    .block-content {
      float: left;
      width: 200px;
      padding: 15px;
      border-bottom: 1px solid #bebebe;
      background: #ffffff;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 5px 5px;
    }
    .block-content2 {
      float: left;
      width: 200px;
      padding: 15px;
      border-bottom: 1px solid #bebebe;
      background: #ffffff;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
    }
    p {
      float: left;
      width: 100%;
      margin: 0 0 10px;
      padding: 0;
      color: #858585;
      &.support {
        float: left;
        width: 170px;
        margin: 0;
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #f0f0f0;
        a {
          color: #933;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        span {
          color: #565656;
        }
      }
    }
    h2.nice-title2 {
      float: left;
      width: 215px;
      padding-left: 15px;
      height: 40px;
      line-height: 40px;
      margin: 0;
      border-bottom: 1px solid #d9d9d9;
      color: #343434;
      font-family: "Arial", "Helvetica", "sans-serif";
      font-size: 16px;
      font-weight: normal;
      text-transform: uppercase;
      background: #f5f5f5;
      background: -moz-linear-gradient(top,#ffffff 0%,#f5f5f5 100%);
      background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffffff),color-stop(100%,#f5f5f5));
      background: -webkit-linear-gradient(top,#ffffff 0%,#f5f5f5 100%);
      background: -o-linear-gradient(top,#ffffff 0%,#f5f5f5 100%);
      background: -ms-linear-gradient(top,#ffffff 0%,#f5f5f5 100%);
      background: linear-gradient(top,#ffffff 0%,#f5f5f5 100%);
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px 5px 0 0;
    }
  }
}
#right-column {
  float: left;
  width: 720px;
}
#wrap-categories {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  .wrap-title-black {
    .expanded {
      float: left;
      width: 40px;
      height: 40px;
      background: url("../images/cat-expanded-icon.png") no-repeat center center;
      &:hover {
        cursor: pointer;
      }
    }
    .collapsed {
      float: left;
      width: 40px;
      height: 40px;
      background: url("../images/cat-expanded-icon.png") no-repeat center center;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.blk22 {
  width: 230px;
  padding: 0 0 10px;
}
#newsletter-form {
  float: left;
  width: 100%;
  p {
    float: left;
    width: 100%;
    margin: 0 0 10px;
    padding: 0;
    color: #858585;
    input {
      padding: 0;
      height: 29px;
      &.nice-i {
        float: left;
        width: 178px;
      }
      &.nice-s {
        float: left;
        width: 75px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
#brand-form {
  float: left;
  width: 100%;
  .checkline {
    float: left;
    width: 210px;
    padding: 5px 0 5px 20px;
    border-bottom: 1px solid #f0f0f0;
    input {
      float: left;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin: 0;
    }
    span {
      float: left;
      width: 175px;
      height: 20px;
      padding-left: 5px;
      line-height: 20px;
      font-size: 13px;
      color: #24659c;
    }
  }
}
#price-form {
  float: left;
  width: 100%;
  .checkline {
    float: left;
    width: 210px;
    padding: 5px 0 5px 20px;
    border-bottom: 1px solid #f0f0f0;
    input {
      float: left;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin: 0;
    }
    span {
      float: left;
      width: 175px;
      height: 20px;
      padding-left: 5px;
      line-height: 20px;
      font-size: 13px;
      color: #24659c;
    }
  }
}
#promo-banners {
  float: left;
  width: 100%;
  height: 405px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background: none repeat scroll 0 0 transparent;
  #slides-pager {
    bottom: 20px;
    float: left;
    position: absolute;
    right: 20px;
    z-index: 100;
    .position {
      float: left;
      background: #ffffff;
      padding: 5px 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 30px;
      a {
        float: left;
        height: 22px;
        line-height: 22px;
        width: 22px;
        text-align: center;
        color: #3885c0;
        margin: 0 2px;
        font-weight: bold;
        text-decoration: none;
        &.activeSlide {
          background: #2b2b2b;
          color: #ffffff;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 45px;
        }
      }
    }
  }
}
#promo-large-banners {
  float: left;
  width: 100%;
  height: 405px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background: none repeat scroll 0 0 transparent;
  #slides-pager {
    bottom: 20px;
    float: left;
    position: absolute;
    right: 20px;
    z-index: 100;
    .position {
      float: left;
      background: #ffffff;
      padding: 5px 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 30px;
      a {
        float: left;
        height: 22px;
        line-height: 22px;
        width: 22px;
        text-align: center;
        color: #3885c0;
        margin: 0 2px;
        font-weight: bold;
        text-decoration: none;
        &.activeSlide {
          background: #2b2b2b;
          color: #ffffff;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 45px;
        }
      }
    }
  }
}
#slide-show {
  float: left;
  width: 100%;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 5px 0 #bbb;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  a {
    float: left;
    width: 100%;
  }
  img {
    float: left;
    border: 0;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
  }
}
#content {
  float: left;
  width: 100%;
}
.view-featured-products-page {
  padding: 10px 0;
  tr {
    padding: 10px 0;
    white-space: nowrap;
    td {
      background-color: #fff;
      background-position: inherit;
      background-repeat: inherit;
      border-bottom-color: #dfdfdf;
      //Instead of the line below you could use @include border-bottom-left-radius($radius)
      border-bottom-left-radius: 5px;
      //Instead of the line below you could use @include border-bottom-right-radius($radius)
      border-bottom-right-radius: 5px;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      //Instead of the line below you could use @include border-top-left-radius($radius)
      border-top-left-radius: 5px;
      //Instead of the line below you could use @include border-top-right-radius($radius)
      border-top-right-radius: 5px;
      display: inline-block;
      float: left;
      margin-right: 15px;
      margin-top: 10px;
      padding: 0;
      width: 230px;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 0 5px 0 #bbb;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      float: none;
      margin: 5px 5px 10px;
      width: 230px;
      background: #fff;
      vertical-align: top;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      border: 0 solid #ccc;
      .field-name-field-images {
        margin-left: auto;
        margin-right: auto;
        width: 215px;
        background: #fff;
        a {
          display: block;
          margin: 0 auto;
          width: 200px;
          //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          text-shadow: 1px 1px 0 #571615;
          filter: dropshadow(color=#571615, offx=1, offy=1);
        }
      }
      .views-field-title {
        text-align: center;
        color: #993333;
        font-weight: bold;
        line-height: 15px;
        margin: 10px 0 0;
        margin-top: 0;
        padding-top: 10px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px 5px 0 0;
        margin-bottom: 8px;
        font-size: 1.1em;
        height: auto;
        padding-bottom: 7px;
        background: #fff;
        a {
          color: #333;
        }
      }
    }
  }
  .category {
    height: auto;
    font-size: 1em;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 5px 5px;
    border-top: 2px solid #777;
    margin-top: 10px;
    background: rgba(25,25,25,1);
    background: -moz-linear-gradient(top,rgba(61,61,61,1) 0%,rgba(25,25,25,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(61,61,61,1)),color-stop(100%,rgba(25,25,25,1)));
    background: -webkit-linear-gradient(top,rgba(61,61,61,1) 0%,rgba(25,25,25,1) 100%);
    background: -o-linear-gradient(top,rgba(61,61,61,1) 0%,rgba(25,25,25,1) 100%);
    background: -ms-linear-gradient(top,rgba(61,61,61,1) 0%,rgba(25,25,25,1) 100%);
    background: linear-gradient(top,rgba(61,61,61,1) 0%,rgba(25,25,25,1) 100%);
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr='#3d3d3d', endcolorstr='#191919',gradienttype=0 );
    color: #fff;
    div {
      .category-wrap {
        padding: 10px 15px;
      }
      div a {
        text-decoration: none;
        color: #c29700;
        &:hover {
          color: #c29700;
        }
      }
    }
  }
}
.view-collection-products {
  padding: 10px 0;
  tr {
    padding: 10px 0;
    white-space: nowrap;
    td {
      background-color: #ffffff;
      border-bottom: 1px solid #dfdfdf;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px 5px 5px 5px;
      display: inline-block;
      float: left;
      margin-top: 20px;
      margin-right: 15px;
      margin-bottom: 10px;
      padding: 0;
      width: 230px;
      background: #fff;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 0 5px 0 #bbb;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      float: none;
      margin: 5px 5px 10px;
      width: 230px;
      background: #fff;
      vertical-align: top;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      border: 0 solid #ccc;
      .views-field-title {
        text-align: center;
        color: #993333;
        font-size: 15px;
        font-weight: bold;
        line-height: 15px;
        height: 30px;
        margin: 10px 0 0;
      }
    }
  }
  div {
    &.view-content table.views-view-grid tbody tr td div {
      &.views-field-field-images {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
      }
      &.views-field span.field-content a {
        float: right;
        font-weight: normal;
        font-size: 13px;
        margin: 5px 0;
      }
    }
    &.views-field-url span.field-content a {
      padding-right: 5px;
    }
  }
}
#inline-product-list {
  float: left;
  width: 100%;
  background: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #bebebe;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 5px 5px;
  .prd {
    float: left;
    width: 680px;
    padding: 20px;
    border-bottom: 1px solid #dfdfdf;
    .product-photo {
      float: left;
      width: 90px;
      a {
        float: left;
        width: 90px;
        img {
          float: left;
          width: 90px;
        }
      }
    }
    .product-info {
      float: left;
      width: 270px;
      margin-left: 20px;
      h3 {
        float: left;
        width: 100%;
        font-size: 14px;
        color: #993333;
        margin: 5 ox 0;
        padding: 0;
        a {
          float: left;
          width: 100%;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      p {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
        color: #8b8b8b;
        a {
          font-weight: bold;
          color: #4385bd;
          padding-left: 10px;
          background: url("../images/details-arrow.png") no-repeat left center;
        }
      }
    }
    .product-reviews {
      float: left;
      width: 130px;
      margin-left: 20px;
      margin-top: 15px;
      p {
        float: left;
        width: 100%;
        color: #5c5c5c;
        margin: 0 0 5px;
        padding: 0;
        a {
          color: #993333;
        }
      }
    }
    .product-price {
      float: left;
      width: 130px;
      margin-left: 20px;
      margin-top: 15px;
      p {
        float: left;
        width: 100%;
        font-size: 20px;
        color: #414141;
        margin: 0 0 5px;
        padding: 0;
        a.addcart {
          float: left;
          width: 110px;
          &:hover {
            float: left;
            width: 112px;
            height: 28px;
            line-height: 28px;
          }
        }
      }
    }
    .product-price2 {
      float: left;
      margin-left: 10px;
      margin-top: 25px;
      width: 130px;
      span {
        float: left;
        width: 100%;
        color: #525252;
      }
      p {
        color: #3b3b3b;
        float: left;
        font-size: 20px;
        margin: 0 0 5px;
        padding: 0;
        width: 100%;
      }
    }
    .product-update {
      float: left;
      margin-left: 10px;
      margin-top: 15px;
      width: 150px;
      form {
        float: left;
        width: 100%;
        div {
          float: left;
          width: 100%;
          span {
            color: #5a5a5a;
            float: left;
            line-height: 21px;
            margin-right: 5px;
            width: 20px;
          }
          input {
            &.i {
              float: left;
              width: 30px;
              border: 1px solid #b6b6b6;
              height: 19px;
              line-height: 19px;
              padding: 0 5px;
              margin: 0 5px 0 0;
              color: #5a5a5a;
              text-align: center;
            }
            &.nice-s {
              float: left;
              width: 65px;
              height: 21px;
              line-height: 21px;
              font-size: 16px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .links {
        float: left;
        width: 145px;
        margin-left: 5px;
        font-size: 11px;
        margin-top: 10px;
        a {
          background: url("../images/wishlist-arrow.png") no-repeat scroll left center transparent;
          color: #007dce;
          padding-left: 8px;
          text-decoration: underline;
          line-height: 18px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .line-item-summary {
    padding: 20px 20px 0 20px;
    float: right;
    width: 100%;
  }
  .form-actions {
    padding: 0 20px 20px;
    float: right;
    width: 100%;
  }
  .commerce-add-to-cart .form-submit {
    margin-top: 0;
    float: none;
  }
  .product-photo input {
    margin-top: 30px;
  }
}
.wrap-rating {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  .rating-summary {
    float: left;
    width: 50px;
    margin-left: 5px;
    color: #5c5c5c;
  }
}
.star-rating {
  background: url("../images/rating-stars.png") left -1000px repeat-x;
  float: left;
  position: relative;
  width: 75px;
  height: 14px;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  background-position: left top;
  a {
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -1000em;
    height: 14px;
    line-height: 14px;
    outline: none;
    overflow: hidden;
    border: none;
    &:hover {
      background: url("../images/rating-stars.png") left -1000px repeat-x;
      background-position: left bottom;
    }
    &:active {
      background: url("../images/rating-stars.png") left -1000px repeat-x;
      background-position: left bottom;
    }
    &:focus {
      background: url("../images/rating-stars.png") left -1000px repeat-x;
      background-position: left bottom;
    }
    &.one-star {
      width: 20%;
      z-index: 6;
    }
    &.two-stars {
      width: 40%;
      z-index: 5;
    }
    &.three-stars {
      width: 60%;
      z-index: 4;
    }
    &.four-stars {
      width: 80%;
      z-index: 3;
    }
    &.five-stars {
      width: 100%;
      z-index: 2;
    }
  }
  .current-rating {
    background: url("../images/rating-stars.png") left -1000px repeat-x;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -1000em;
    height: 14px;
    line-height: 14px;
    outline: none;
    overflow: hidden;
    border: none;
    z-index: 1;
    background-position: left center;
  }
  li {
    display: inline;
  }
}
#wrap-pages {
  float: left;
  padding: 20px 20px 10px 20px;
  width: 680px;
  &.round {
    background: #ffffff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    padding: 0 20px;
    margin-top: 15px;
  }
  .left {
    float: left;
    width: 100px;
    height: 45px;
    line-height: 45px;
    color: #232222;
  }
  .right {
    float: left;
    width: 580px;
    height: 27px;
    line-height: 27px;
    margin: 9px 0;
    text-align: right;
    a {
      height: 27px;
      line-height: 27px;
      color: #993333;
      text-decoration: none;
      border-right: 1px solid #eeeeee;
      padding-left: 7px;
      padding-right: 10px;
      padding-top: 0;
      padding-bottom: 0;
      display: inline-block;
      margin-bottom: -7px;
      &.last {
        border: none;
      }
      &.active {
        color: #333333;
        font-weight: bold;
      }
      &:hover {
        color: #333333;
        font-weight: bold;
      }
      &.previous-button {
        border: 0;
        padding: 0;
        display: inline-block;
        height: 27px;
        width: 27px;
        background: url("../images/previous-button.png") no-repeat center center;
        &:hover {
          background: url("../images/previous-button-hover.png") no-repeat center center;
        }
      }
      &.next-button {
        border: 0;
        padding: 0;
        display: inline-block;
        height: 27px;
        width: 27px;
        background: url("../images/next-button.png") no-repeat center center;
        &:hover {
          background: url("../images/next-button-hover.png") no-repeat center center;
        }
      }
    }
  }
}
#block-product-list {
  float: left;
  width: 735px;
  list-style: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  float: none;
  overflow: hidden;
  background: none;
  width: auto;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 5px 5px;
  .prd {
    float: left;
    display: inline-block;
    width: 230px;
    margin-top: 10px;
    margin-right: 15px;
    padding: 0;
    border-bottom: 1px solid #dfdfdf;
    background: #ffffff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    &.middle {
      margin: 10px 15px 0;
    }
    .product-name {
      float: left;
      width: 100%;
      height: 30px;
      margin: 10px 0;
      overflow: hidden;
      h2 {
        float: left;
        width: 200px;
        line-height: 15px;
        font-size: 12px;
        color: #993333;
        font-weight: bold;
        margin: 0 15px;
        padding: 0;
        text-align: center;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .product-photo {
      float: left;
      width: 230px;
      height: 210px;
      padding: 0;
      margin: 0;
      overflow: hidden;
      border-bottom: 1px solid #e6e6e6;
      a {
        float: left;
        width: 100%;
        img {
          float: left;
          width: 200px;
          height: 200px;
          margin: 0 15px 10px;
        }
      }
    }
    .product-info {
      float: left;
      width: 200px;
      margin: 5px 15px 0;
      .first-row {
        float: left;
        width: 100%;
        height: 20px;
        line-height: 20px;
        margin-bottom: 5px;
        .oldprice {
          float: left;
          width: 130px;
          height: 20px;
          line-height: 20px;
          color: #232222;
          span {
            text-decoration: line-through;
            font-weight: bold;
          }
        }
        .details {
          float: left;
          width: 70px;
          height: 20px;
          line-height: 20px;
          text-align: right;
          a {
            background: url("../images/details-arrow.png") no-repeat scroll left center transparent;
            color: #24659c;
            font-weight: normal;
            padding-left: 10px;
            float: right;
          }
        }
      }
      .second-row {
        float: left;
        width: 100%;
        height: 27px;
        line-height: 27px;
        padding-bottom: 10px;
        .price {
          float: left;
          width: 80px;
          height: 27px;
          line-height: 27px;
          color: #414141;
          font-size: 20px;
        }
        .addcart {
          float: left;
          width: 120px;
          height: 27px;
          line-height: 27px;
          a {
            float: right;
            width: 110px;
            height: 25px;
            line-height: 25px;
            &:hover {
              width: 112px;
              height: 26px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
  .commerce-add-to-cart {
    .form-submit {
      margin-top: 0;
    }
    .wishlist {
      display: none;
      visibility: hidden;
    }
  }
  ul.tabs {
    width: 700px;
  }
}
#wrap-product-info {
  float: left;
  width: 100%;
  .share-buttons {
    float: left;
    width: 210px;
    margin-left: 30px;
    margin-right: 10px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    text-align: right;
  }
  h1.nice-title {
    width: 450px;
  }
}
#wrap-cart-info {
  float: left;
  width: 100%;
  .count-cart {
    float: left;
    width: 200px;
    padding-right: 20px;
    margin-left: 30px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    text-align: right;
  }
  h1.nice-title.cart {
    margin-left: 20px;
    padding-left: 30px;
    width: 420px;
    background: url("../images/cart-icon-white.png") no-repeat left center;
  }
}
#wrap-checkout {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  background: #ffffff;
  border-bottom: 1px solid #bebebe;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 5px 5px;
  .footer-links {
    float: left;
    padding: 0 20px;
    width: 680px;
    .l {
      float: left;
      width: 50%;
      a {
        float: left;
        width: 100px;
        height: 25px;
        line-height: 25px;
        &:hover {
          float: left;
          width: 102px;
          height: 26px;
          line-height: 26px;
        }
      }
    }
    .r {
      float: left;
      width: 50%;
      a {
        float: right;
        width: 100px;
        height: 25px;
        line-height: 25px;
        &:hover {
          float: right;
          width: 102px;
          height: 26px;
          line-height: 26px;
        }
      }
    }
  }
  h1.nice-title.checkout {
    margin-left: 20px;
    padding-left: 30px;
    width: 670px;
    background: url("../images/checkout-icon.png") no-repeat left center;
  }
}
.service-links {
  li {
    list-style: none;
    float: left;
    max-width: 69px;
    padding: 0 5px;
  }
}
#product-content {
  background: #ffffff;
  width: 100%;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 5px 5px;
  float: none;
  overflow: hidden;
  .firstrow {
    width: 100%;
    overflow: hidden;
    float: none;
  }
  .wrap-images {
    float: left;
    width: 400px;
    .bigimage {
      float: left;
      width: 100%;
      margin: 15px 0 5px 15px;
      a {
        float: left;
        img {
          float: left;
          border: 1px solid #e9e9e9;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 5px;
        }
      }
    }
  }
  .wrap-image-list {
    float: left;
    width: 100%;
    a {
      float: left;
      width: 67px;
      height: 67px;
      margin: 0 4px;
      img {
        float: left;
        width: 65px;
        height: 65px;
        margin: 0;
        border: 1px solid #e9e9e9;
        padding: 1px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px;
        &:hover {
          padding: 0;
          border: 2px solid #d7d7d7;
        }
      }
    }
  }
  div {
    &.firstrow div.wrap-images div.bigimage div.view div.view-content div.views-row div.cloud-zoom-container div.cloud-zoom-gallery-thumbs a.cloud-zoom-gallery {
      margin: 10px 10px 5px 0;
    }
    &.secondrow div {
      &.wrap-title-black {
        float: right;
        width: 275px;
        margin: 0 20px 0 0;
        padding: 0;
      }
      &.view-related-products {
        border: 1px solid #e5e5e5;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0 5px 5px 5px;
        float: right;
        margin: 0 20px;
        padding: 0;
        width: 275px;
      }
    }
  }
  .wrap-product-short {
    float: right;
    height: 100%;
    width: 320px;
    h2 {
      float: left;
      width: 100%;
      margin: 5px 0;
      padding: 0;
      font-size: 22px;
      color: #343434;
    }
    h3 {
      float: left;
      font-size: 15px;
      margin: 0 10px 0 0;
      padding: 0;
      color: #b7b7b7;
    }
    .summary {
      float: left;
      width: 100%;
      margin: 15px 0;
      p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: #525252;
        line-height: 20px;
      }
    }
  }
  .wrap-special-info {
    border: 2px solid #e5e5e5;
    float: right;
    margin: 15px 15px 0 0;
    padding: 10px;
    width: 275px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 10px;
    margin-bottom: 20px;
    float: none;
    overflow: hidden;
    .left {
      float: left;
      width: 135px;
      .ratingstars {
        float: left;
        width: 100%;
        margin: 15px;
      }
      p {
        float: left;
        width: 100%;
        margin: 0 0 5px;
        font-weight: bold;
        color: #5c5c5c;
        &.dotted {
          margin: 10px 0;
          padding: 10px 0;
          border-top: 1px dotted #c6c6c6;
          border-bottom: 1px dotted #c6c6c6;
        }
        span {
          color: #993333;
          font-weight: normal;
        }
        a {
          font-weight: normal;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .right {
      .add-product-cart {
        float: left;
        width: 100%;
        float: none;
        overflow: hidden;
        .oldprice {
          float: left;
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #5a5a5a;
          .l {
            float: left;
            width: 120px;
          }
          .r {
            float: left;
            width: 70px;
            text-align: right;
          }
        }
        .currentprice {
          width: 100%;
          height: 30px;
          padding: 5px 0;
          margin: 10px 0;
          line-height: 30px;
          color: #5f5f5f;
          font-weight: bold;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          .l {
            float: left;
            width: 80px;
          }
          .r {
            font-size: 15px;
            color: #272727;
          }
        }
      }
      form {
        float: left;
        .quantity {
          float: left;
          width: 78px;
          height: 27px;
          span {
            float: left;
            width: 20px;
            line-height: 27px;
            margin-right: 5px;
            color: #5a5a5a;
          }
          input {
            float: left;
            width: 30px;
            height: 25px;
            line-height: 25px;
            padding: 0 5px;
            border: 1px solid #e2e2e2;
            text-align: center;
            color: #c2c2c2;
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 5px;
            //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
            box-shadow: inset 2px 4px 4px -1px rgba(226, 226, 226, 1);
          }
        }
        .add-quantity {
          float: left;
          width: 112px;
          input {
            float: left;
            width: 112px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .wishlist {
        float: left;
        width: 185px;
        margin-right: 5px;
        height: 25px;
        line-height: 25px;
        text-align: right;
        a {
          color: #4599d0;
          padding-left: 8px;
          text-decoration: underline;
          background: url("../images/wishlist-arrow.png") no-repeat left center;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .secondrow {
    float: left;
    margin: 0 0 0 2px;
    width: 400px;
    .wrap-tabs {
      float: left;
      width: 360px;
      margin: 0 10px;
      .tabs {
        float: left;
        width: 360px;
        margin-left: 0;
        a {
          float: left;
          height: 30px;
          line-height: 30px;
          padding: 0 20px;
          border-top: 1px solid #e5e5e5;
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
          margin-right: 5px;
          font-size: 13px;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 5px 5px 0 0;
          background: #ebebeb;
          background: -moz-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
          background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffffff),color-stop(100%,#ebebeb));
          background: -webkit-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
          background: -o-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
          background: -ms-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
          background: linear-gradient(top,#ffffff 0%,#ebebeb 100%);

          filter: progid:dximagetransform.microsoft.gradient(startcolorstr = '#ffffff', endcolorstr = '#ebebeb', gradienttype = 0);
          &.active {
            color: #5c5c5c;
            background: #ffffff !important;
            font-weight: bold;
          }
        }
      }
      .wrap-tabs-content {
        float: left;
        width: 360px;
        padding: 9px;
        border: 1px solid #e5e5e5;
        color: #525252;
        font-size: 15px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0 5px 5px 5px;
        #tab2-content {
          display: none;
        }
        #tab3-content {
          display: none;
        }
      }
    }
    .wrap-related {
      float: left;
      width: 250px;
      h3 {
        float: left;
        width: 233px;
        height: 30px;
        margin: 31px 0 0;
        padding-left: 15px;
        line-height: 30px;
        color: #5c5c5c;
        font-weight: bold;
        font-size: 13px;
        border: 1px solid #e5e5e5;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px 5px 0 0;
        background: #ebebeb;
        background: -moz-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
        background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffffff),color-stop(100%,#ebebeb));
        background: -webkit-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
        background: -o-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
        background: -ms-linear-gradient(top,#ffffff 0%,#ebebeb 100%);
        background: linear-gradient(top,#ffffff 0%,#ebebeb 100%);
        filter: progid:dximagetransform.microsoft.gradient(startcolorstr = '#ffffff', endcolorstr = '#eae9e9', gradienttype = 0);
      }
      ul#related-products-nav {
        float: left;
        width: 248px;
        border: 1px solid #e5e5e5;
        margin: 0;
        padding: 0 0 10px;
        list-style: none;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0 0 5px 5px;
        li {
          float: left;
          width: 230px;
          padding: 9px;
          border-bottom: 1px solid #e5e5e5;
          .image {
            float: left;
            width: 50px;
            height: 50px;
            margin-right: 10px;
            a {
              float: left;
              img {
                float: left;
                width: 50px;
                height: 50px;
                border: 0;
              }
            }
          }
          .info {
            float: left;
            width: 170px;
            height: 50px;
            h4 {
              float: left;
              margin: 0;
              font-size: 11px;
              font-weight: normal;
              a {
                color: #24659c;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            p {
              float: left;
              margin: 0;
              color: #3a3a3a;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .wrap-view {
    float: right;
    margin: 0 15px 0 0;
    width: 295px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 5px 0 #bbb;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
  }
  .block-webform {
    h2 {
      margin: 10px 0 0 15px;
      float: none;
    }
    .block-content {
      margin: 10px 0;
      padding: 0 10px;
      float: none;
      width: auto;
      padding-left: 10px;
    }
  }
  .pricing-table {
    font-size: 1.1em;
    margin-bottom: 40px;
    margin-top: 0;
    clear: both;
    .inner {
      margin: 10px 20px;
      border: none;
    }
    table {
      width: 100%;
    }
  }
}
#cloud-zoom-big {
  display: none !important;
}
.cloud-zoom-lens {
  display: none !important;
}
.cloud-zoom-tint {
  display: none !important;
}
.form-type-select {
  .ajax-processed {
    margin: 0 0 0 10px;
  }
  label {
    float: left;
  }
}
#edit-line-item-fields-field-logo-to-upload-und-0-ajax-wrapper {
  .form-item .description {
    color: #5f5f5f;
    font-weight: 500;
    margin: -24px 0 0 111px;
    float: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    clear: both;
    font-size: 10px;
    line-height: 1.1;
  }
  div.form-item div.image-widget div.image-widget-data span {
    &.file {
      float: right;
    }
    &.file-size {
      float: right;
    }
  }
}
.field-name-field-custom-text {
  .form-managed-file {
    .file {
      font-size: 12px;
      .file-icon {
        vertical-align: middle;
      }
    }
    .file-size {
      font-size: 12px;
      float: right;
      font-size: 12px;
      color: #555;
    }
  }
  .form-item {
    margin: 0;
  }
}
#webform-client-form-54 {
  border-left: 1px solid #e5e5e5;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 25px 0 2px;
  padding: 10px;
}
#wrap-payment {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  background: #ffffff;
  border-bottom: 1px solid #bebebe;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 5px 5px;
  a.continue {
    clear: both;
    float: right;
    width: 170px;
    height: 25px;
    line-height: 25px;
    margin-top: 20px;
    margin-right: 20px;
    &:hover {
      width: 172px;
      height: 26px;
      line-height: 26px;
    }
  }
}
#payment-block {
  float: right;
  width: 320px;
  border: 5px solid #d9d9d9;
  background: #f7f7f7;
  padding: 15px;
  margin-right: 20px;
  margin-top: 20px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 10px;
  .row {
    float: left;
    width: 100%;
    color: #525252;
    .l {
      float: left;
      width: 50%;
      line-height: 20px;
      span {
        color: #24659c;
        font-weight: bold;
      }
    }
    .r {
      float: left;
      width: 50%;
      line-height: 20px;
      text-align: right;
    }
  }
  .row2 {
    float: left;
    width: 100%;
    color: #525252;
    height: 44px;
    background: url("../images/payment-bg.png") repeat-x left center;
    margin: 5px 0;
    .l {
      float: left;
      width: 50%;
      line-height: 34px;
      padding: 5px 0;
      font-weight: bold;
    }
    .r {
      float: left;
      width: 50%;
      color: #3b3b3b;
      font-weight: bold;
      font-size: 20px;
      line-height: 34px;
      padding: 5px 0;
      text-align: right;
    }
  }
  .row3 {
    float: left;
    width: 274px;
    margin: 5px 23px 0;
    text-align: center;
    color: #525252;
    height: 36px;
    a {
      &.p {
        float: left;
        height: 36px;
        img {
          float: left;
          border: 0;
          margin-top: 6px;
        }
      }
      &.checkout {
        float: left;
        width: 110px;
        height: 35px;
        line-height: 35px;
      }
    }
    span {
      float: left;
      height: 36px;
      line-height: 36px;
      margin: 0 5px;
    }
  }
}
#wrap-cart-summary {
  float: left;
  width: 100%;
  .wrap-title-black h2.nice-title {
    float: left;
    width: 180px;
    padding-left: 30px;
    margin: 0 0 0 20px;
    background: url("../images/cart-icon-white.png") no-repeat left center;
  }
}
#cart-summary {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  background: #ffffff;
  border-bottom: 1px solid #bebebe;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 5px 5px;
  .row {
    float: left;
    width: 210px;
    padding: 0 10px;
    color: #525252;
    .l {
      float: left;
      width: 60%;
      line-height: 20px;
    }
    .r {
      float: left;
      width: 40%;
      line-height: 20px;
      text-align: right;
    }
  }
  .row2 {
    float: left;
    width: 210px;
    margin: 10px 10px 0;
    color: #525252;
    border-top: 1px dotted #d3d3d3;
    .l {
      float: left;
      width: 50%;
      line-height: 26px;
      padding: 5px 0;
      font-weight: bold;
    }
    .r {
      float: left;
      width: 50%;
      color: #3b3b3b;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      padding: 5px 0;
      text-align: right;
    }
  }
  a.review-details {
    float: left;
    width: 200px;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: bold;
    color: #24659c;
    border-bottom: 1px solid #f0f0f0;
  }
}
#checkout-main {
  float: left;
  width: 680px;
  padding: 20px;
  .subinfo {
    float: left;
    width: 100%;
    height: 35px;
    .l {
      float: left;
      width: 520px;
      font-size: 24px;
      font-weight: bold;
      color: #272727;
      line-height: 35px;
    }
    .r {
      float: left;
      width: 100px;
      margin-left: 25px;
      height: 35px;
      line-height: 17px;
      padding-left: 35px;
      font-size: 13px;
      color: #24659c;
      background: url("../images/lock-icon.png") no-repeat left center;
    }
    .r2 {
      float: left;
      width: 100px;
      margin-left: 25px;
      height: 35px;
      line-height: 35px;
      padding-left: 35px;
      font-size: 13px;
      color: #525252;
    }
  }
  .login-box {
    float: left;
    width: 640px;
    padding: 15px;
    border: 5px solid #d9d9d9;
    background: #f7f7f7;
    margin-top: 20px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 10px;
    .returning {
      float: left;
      width: 310px;
      margin-right: 20px;
      h3 {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        color: #272727;
      }
      form {
        float: left;
        width: 290px;
        margin: 10px 0 0;
        padding: 9px;
        background: #ffffff;
        border: 1px solid #e4e4e4;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px;
        p {
          float: left;
          width: 100%;
          margin: 0 0 5px;
          font-size: 11px;
          color: #525252;
          label {
            float: left;
            width: 100%;
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          input {
            &.nice-i {
              float: left;
              width: 178px;
            }
            &.nice-s {
              float: left;
              width: 75px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          a {
            float: left;
            padding-left: 10px;
            height: 25px;
            line-height: 25px;
            font-size: 11px;
            color: #24659c;
          }
        }
      }
    }
    .new {
      float: left;
      width: 310px;
      h3 {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        color: #272727;
      }
      .table {
        float: left;
        width: 290px;
        margin: 10px 0 0;
        padding: 9px;
        background: #ffffff;
        border: 1px solid #e4e4e4;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px;
        p {
          float: left;
          width: 100%;
          margin: 0 0 5px;
          font-size: 11px;
          color: #525252;
          a {
            &.can {
              float: left;
              width: 180px;
              height: 25px;
              line-height: 25px;
            }
            &.cag {
              clear: both;
              float: left;
              width: 180px;
              height: 25px;
              line-height: 25px;
              &:hover {
                width: 182px;
                height: 26px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    float: left;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 11px;
    color: #525252;
  }
}
#checkout-steps {
  float: left;
  width: 680px;
  height: 50px;
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  .r {
    float: left;
    width: 100px;
    margin-left: 25px;
    height: 35px;
    line-height: 17px;
    padding: 8px 0 8px 35px;
    font-size: 13px;
    color: #24659c;
    background: url("../images/lock-icon.png") no-repeat left center;
  }
  .l {
    float: left;
    width: 510px;
    margin-right: 10px;
    font-size: 11px;
    color: #ababab;
    height: 50px;
    background: url("../images/steps-bg.png") repeat-x left center;
    .step {
      float: left;
      width: 85px;
      height: 50px;
      .step-id {
        float: left;
        width: 28px;
        height: 28px;
        margin-left: 27px;
        margin-right: 28px;
        line-height: 28px;
        border: 1px solid #d3d3d3;
        text-align: center;
        background: #ffffff;
        color: #8f8f8f;
        font-size: 17px;
        font-weight: bold;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 20px;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: inset 0 3px 3px -1px rgba(229, 229, 229, 1);
        &.active {
          float: left;
          width: 26px;
          height: 26px;
          line-height: 26px;
          margin-left: 27px;
          margin-right: 28px;
          border: 2px solid #ffd800;
          text-align: center;
          color: #ffffff;
          font-size: 17px;
          font-weight: bold;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 20px;
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: inset 0 0 0 0 rgba(229, 229, 229, 1);
          background: #231f20;
          background: -moz-linear-gradient(top,#4d4547 0%,#231f20 100%);
          background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#4d4547),color-stop(100%,#231f20));
          background: -webkit-linear-gradient(top,#4d4547 0%,#231f20 100%);
          background: -o-linear-gradient(top,#4d4547 0%,#231f20 100%);
          background: -ms-linear-gradient(top,#4d4547 0%,#231f20 100%);
          background: linear-gradient(top,#4d4547 0%,#231f20 100%);

          filter: progid:dximagetransform.microsoft.gradient(startcolorstr = '#4d4547', endcolorstr = '#231f20', gradienttype = 0);
        }
      }
      .step-name {
        float: left;
        width: 100%;
        height: 20px;
        line-height: 20px;
        text-align: center;
        &.active {
          color: #2b2b2b;
          font-weight: bold;
        }
      }
    }
  }
}
#checkout1-form {
  float: left;
  width: 670px;
  border: 5px solid #d9d9d9;
  background: #f7f7f7;
  margin-top: 10px;
  padding: 20px 0;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 10px;
  input {
    float: left;
  }
  select {
    float: left;
    float: left;
    width: 155px;
    margin: 0;
    color: #c2c2c2;
    border: 1px solid #e2e2e2;
    padding: 4px 5px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: inset 0 -6px 6px -1px rgba(229, 229, 229, 1);
  }
  .row {
    float: left;
    width: 630px;
    padding: 0 20px;
    margin-bottom: 10px;
    color: #525252;
    font-size: 13px;
    input {
      width: 280px;
    }
    .r2 {
      float: left;
      width: 150px;
      input {
        width: 114px;
      }
      label {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        line-height: 16px;
      }
    }
    .l {
      float: left;
      width: 315px;
      label {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        line-height: 16px;
      }
    }
    .r {
      float: left;
      width: 315px;
      label {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        line-height: 16px;
        &.note {
          color: #24659c;
          font-weight: normal;
          margin-top: 21px;
          line-height: 26px;
          height: 26px;
          margin-bottom: 0;
        }
      }
      a {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        line-height: 16px;
        &.note {
          color: #24659c;
          font-weight: normal;
          margin-top: 21px;
          line-height: 26px;
          height: 26px;
          margin-bottom: 0;
        }
      }
    }
    .c {
      float: left;
      width: 160px;
      margin-right: 5px;
      label {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        line-height: 16px;
      }
    }
  }
  .row2 {
    float: left;
    width: 630px;
    padding: 0 20px;
    margin-bottom: 10px;
    color: #525252;
    font-size: 13px;
    label {
      float: left;
      width: 100%;
      margin-bottom: 5px;
      line-height: 16px;
    }
    input {
      width: 595px;
    }
  }
  .row3 {
    float: left;
    width: 630px;
    padding: 0 20px;
    margin-bottom: 10px;
    color: #525252;
    font-size: 13px;
    border-top: 1px dotted #a3a3a3;
    border-bottom: 1px dotted #a3a3a3;
    padding: 20px;
    height: 24px;
    span {
      float: left;
      font-weight: bold;
      margin-left: 10px;
      height: 24px;
      line-height: 24px;
    }
    input.r {
      float: left;
      height: 24px;
      line-height: 24px;
      border: 0;
      margin: 0;
    }
  }
  p {
    float: left;
    width: 630px;
    padding: 0 20px;
    margin-bottom: 10px;
    color: #525252;
    font-size: 13px;
  }
  label.required {
    font-weight: bold;
  }
}
.detailsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    margin: 0;
    padding: 10px;
    vertical-align: middle;
    &.itemDetailsFeatureRow1 {
      background-color: #efefef;
    }
  }
}
#messages {
  float: left;
  width: 100%;
  .messages {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 15px;
  }
}
#nobreadcrumb {
  float: left;
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
}
.footer-block {
  padding-bottom: 10px;
  clear: both;
  float: left;
  .contextual-links-wrapper ul {
    width: auto;
    a {
      width: auto;
    }
  }
}
.footer-block-left {
  p {
    margin: 0;
    padding: 0;
  }
  div {
    margin: 0;
    padding: 0;
  }
}
.field-type-taxonomy-term-reference {
  clear: both;
  float: none;
  div {
    &.field-item {
      float: left;
      margin-right: 10px;
    }
    &.field-label {
      float: left;
      margin-right: 10px;
    }
  }
}
table {
  &.views-table {
    width: 100%;
    td {
      background-color: #fff;
      background: none;
    }
  }
  &.commerce-price-formatted-components {
    td {
      background-color: #fff;
      background: none;
    }
    tbody {
      border: none;
    }
  }
}
.sharethis-buttons {
  float: right;
  margin: 12px 10px 0 0;
}
.region-sidebar-right {
  .block {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 5px 0 #bbb;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
  }
  div#block-system-main-menu.left-block div.block-content {
    padding: 0;
    width: 100%;
    ul li {
      border-bottom-color: #f0f0f0;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      float: left;
      margin: 0;
      width: 100%;
      list-style: none;
      a {
        font-size: 13px;
        text-decoration: initial;
        float: none;
        display: block;
        height: auto;
        line-height: 1;
        padding: 12px 12px 12px 25px;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: background 500ms ease-in-out;
        &:hover {
          background: #eee;
        }
      }
      // Don't display child menus.
      ul {
        display: none;
      }
    }
  }
}

div {
  &.commerce_add_to_cart_confirmation_overlay {
    background: transparent url("../images/bg_add_to_cart_overlay.png") left top;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
  }
  &.profile {
    h3 {
      font-size: 1.5em;
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    ul {
      padding: 0 10px 40px;
      margin-top: 0;
      li {
        list-style-type: none;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    h4 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.2em;
      padding-bottom: 5px;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 5px;
      color: #555;
    }
  }
  &.messages.commerce-add-to-cart-confirmation {
    background-color: white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 2px 15px #57595a;
    filter: progid:dximagetransform.microsoft.shadow(color = '#57595a', direction = '180', strength = '5');
    margin-left: -20% !important;
    overflow: hidden;
    padding: 0;
    position: absolute;
    left: 50%;
    width: 450px;
    z-index: 99999;
    border-color: #999;
    color: #555;
    .message-inner {
      .commerce-add-to-cart-confirmation-close {
        background: url("../images/btn_add-to-cart-close.png") no-repeat 0 0;
        display: block;
        height: 17px;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 18px;
      }
      .added-product-title {
        float: left;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 30px 10px 20px;
        text-align: center;
        text-transform: uppercase;
        width: 55%;
        color: #993333;
      }
      .button-wrapper {
        height: 100%;
        padding: 40px 30px;
        position: absolute;
        right: 0;
        width: 25%;
        background-color: #eee;
        z-index: 1000;
        &:after {
          display: block;
          clear: both;
          content: ".";
          height: 0;
          visibility: hidden;
        }
        .button {
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 3px;
          color: white;
          font-weight: bold;
          margin-bottom: 10px;
          padding: 10px;
          text-align: center;
          &.checkout {
            background-color: #993333;
            &:hover {
              cursor: pointer;
            }
          }
          &.continue {
            background-color: #b3b3b3;
            .commerce-add-to-cart-confirmation-close {
              background: none;
              height: auto;
              position: static;
              width: auto;
            }
            &:hover {
              cursor: pointer;
            }
          }
          a {
            color: white;
            display: block;
            font-weight: bold;
            word-wrap: break-word;
          }
        }
      }
    }
    .view-confirm-message-product-display {
      clear: both;
      .view-content {
        padding: 15px;
        position: relative;
        width: 55%;
        .field {
          clear: both;
          margin: 0;
          text-align: left;
          .views-label {
            float: left;
            font-size: 12px;
            font-weight: bold;
            padding-right: 5px;
            color: #555;
          }
          .field-label {
            float: left;
            font-size: 12px;
            font-weight: bold;
            padding-right: 5px;
            color: #555;
            display: inline;
            float: left;
          }
        }
        .views-field-commerce-total {
          clear: both;
          font-size: 20px;
          margin-top: 25px;
          overflow: hidden;
          .views-label {
            float: left;
            padding-right: 5px;
          }
          .field-content {
            display: inline;
            float: right;
            color: #993333;
          }
        }
        .content .field-name-field-images {
          position: relative;
          width: 130px;
          img {
            border: #d7d7d7 solid 1px;
          }
        }
        .commerce-product-sku .commerce-product-sku-label {
          float: left;
          font-size: 12px;
          font-weight: bold;
          padding-right: 5px;
          color: #555;
        }
      }
    }
  }
}
#wrap-featured-products {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px 5px;
  overflow: hidden;
}
#block-search-api-sorts-search-sorts {
  overflow: hidden;
  background: #fff;
  padding-left: 14px;
  margin-top: 10px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 4px 0 #ccc;
  margin: 10px 5px 0;
  h2 {
    font-size: 1em;
    display: inline;
    float: left;
    margin: 1em 0;
  }
  .search-api-sorts {
    margin: 1em 0;
    li {
      display: inline;
    }
  }
  .block-content {
    float: left;
  }
}
.pricing-table {
  tr {
    border: 0;
    &.even {
      background: #fff;
    }
  }
  td {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
  }
  thead {
    border: 1px solid #000;
    border-bottom: none;
  }
  tbody {
    border: 1px solid #ccc;
    border-top: none;
  }
  th {
    color: #fff;
    text-transform: uppercase;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 12px;
    border-bottom: 2px solid #000;
    font-weight: normal;
    background: #221e1f;
    background: -moz-linear-gradient(top,#4e4648 0%,#221e1f 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#4e4648),color-stop(100%,#221e1f));
    background: -webkit-linear-gradient(top,#4e4648 0%,#221e1f 100%);
    background: -o-linear-gradient(top,#4e4648 0%,#221e1f 100%);
    background: -ms-linear-gradient(top,#4e4648 0%,#221e1f 100%);
    background: linear-gradient(top,#4e4648 0%,#221e1f 100%);
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr='#4e4648', endcolorstr='#221e1f',gradienttype=0 );
  }
}
.pricing-schedule-footer {
  color: #555;
  font-style: italic;
  margin: 10px 0;
}
.add-product-cart {
  .description {
    padding-bottom: 15px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .image-widget {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .file-widget {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .field-name-field-logo-to-upload {
    overflow: hidden;
    clear: left;
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  .field-name-field-custom-text {
    overflow: hidden;
    clear: left;
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  .attribute-widgets {
    overflow: hidden;
    clear: left;
    border-bottom: 1px solid #eee;
    width: 100%;
    padding-bottom: 15px;
    line-height: 30px;
  }
  #edit-line-item-fields label {
    padding: 10px 0;
  }
}
.view-related-products {
  padding: 10px;
  .views-field-title {
    text-align: left;
  }
  tr {
    &.even {
      background: none;
    }
    &.odd {
      background: #eee;
    }
  }
  td {
    padding-top: 5px;
  }
}
#block-webform-client-block-32 {
  overflow: hidden;
  clear: both;
}
.page-user {
  #main-content {
    margin-top: 20px;
  }
  ul {
    &.primary {
      margin-top: 26px;
      margin-bottom: 0;
    }
    &.secondary {
      background: #fff;
      margin: 0;
      padding: 10px 0;
    }
  }
  #product-content {
    background: #fff;
  }
  .region-content {
    padding: 20px;
    background: #fff;
    overflow: hidden;
  }
  #edit-account {
    .form-item-name {
      margin-bottom: 2em;
    }
    .form-item-mail {
      margin-bottom: 2em;
    }
  }
  .view-commerce-addressbook {
    td {
      padding: 15px;
      line-height: 1.4;
    }
    .field-name-commerce-customer-address .field-items {
      padding-bottom: 10px;
    }
  }
}
.back-to-login {
  margin-top: 1em;
}
.create-account {
  margin-top: 0;
  width: 270px;
  padding: 15px 0 15px 30px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  float: left;
  padding-bottom: 50px;
  h2 {
    margin-bottom: 0.25em;
    margin-top: 0;
  }
}
#edit-account {
  .description {
    font-size: 0.9em;
    line-height: 1.3;
    color: #555;
    padding-top: 3px;
    padding-left: 2px;
  }
  div.form-item div.password-suggestions {
    background: #fff;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    padding: 6px;
  }
}
#user_login_form {
  width: 370px;
  float: left;
  border-right: 1px solid #ccc;
  .description {
    font-size: 0.9em;
    line-height: 1.3;
    color: #555;
    padding-top: 3px;
    padding-left: 2px;
  }
  h1 {
    margin-top: 10px;
  }
  input {
    margin-left: 0;
  }
}
#user-login {
  #edit-name {
    width: 200px;
  }
  #edit-pass {
    width: 200px;
  }
}
.page-cart {
  #content {
    margin-top: 20px;
  }
  #right-column {
    width: 100%;
  }
}
.page-checkout {
  #content {
    margin-top: 20px;
  }
  #right-column {
    width: 100%;
  }
}
.view-commerce-cart-form {
  padding: 15px;
  table {
    table-layout: fixed;
    th {
      width: 210px;
      &.views-field-commerce-unit-price {
        width: 50px;
        width: 50px;
      }
    }
    .views-field-edit-quantity {
      width: 50px;
    }
    .views-field-edit-delete {
      width: 50px;
    }
    .views-field-commerce-total {
      width: 50px;
    }
  }
  .description {
    white-space: normal;
    color: #777;
  }
  .price {
    margin-left: 0;
    font-size: 1em;
    font-weight: bold;
  }
  .form-submit {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  #edit-submit {
    display: inline;
  }
  #edit-checkout {
    display: inline;
  }
  th {
    vertical-align: bottom;
    padding-top: 11px;
    padding: 5px;
    &.views-field-nothing {
      width: 125px;
    }
  }
  .views-field-view {
    width: 100px;
  }
  .views-field-editablefield {
    width: 100px;
    padding: 5px;
  }
  .views-field {
    padding: 10px 0;
    padding: 5px;
  }
  .entity-commerce-product {
    padding-bottom: 5px;
    .field-name-commerce-price {
      display: none;
    }
  }
  .field-name-commerce-order-total {
    .component-title {
      text-align: right;
    }
    .component-total {
      padding-right: 10px;
    }
  }
  tr {
    &.even {
      background: #fff;
    }
    &.views-row-last {
      border-bottom: 2px solid #ccc;
    }
  }
  td {
    &.price {
      background: none;
    }
    &.views-field {
      padding-top: 10px;
    }
    &.views-field-editablefield {
      padding-top: 10px;
    }
  }
  .image-preview {
    padding-bottom: 0;
  }
  .form-actions {
    margin-top: 0;
  }
  .file-size {
    display: none;
  }
  .editablefield-edit {
    background: none;
    border: none;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    color: #777;
    padding-left: 0;
    margin-left: 0;
  }
  h3 {
    text-align: right;
    font-size: 1em;
    color: #993333;
  }
  input.form-file {
    width: 100%;
  }
  .component-type-commerce-price-formatted-amount td {
    padding: 10px 0;
    background: #f5f5f5;
  }
  tbody .views-field-nothing {
    padding: 10px;
  }
}
.view-commerce-cart-summary {
  padding-top: 10px;
  .price {
    font-size: 1em;
    font-weight: bold;
  }
  td.price {
    background: none;
  }
}
td {
  &.views-field-commerce-total {
    text-align: center;
  }
  &.views-field-commerce-unit-price {
    text-align: center;
  }
}
th {
  &.views-field-commerce-total {
    text-align: center;
  }
  &.views-field-commerce-unit-price {
    text-align: center;
  }
}
.cart-empty-page {
  padding: 30px;
  font-size: 1.3em;
  text-align: center;
}
#commerce-checkout-form-checkout {
  padding: 20px;
  fieldset {
    border: none;
    margin-bottom: 55px;
  }
  .fieldset-legend {
    font-size: 1.5em;
    font-weight: bold;
  }
  tr {
    &.odd {
      background: #fff;
    }
    &.views-row-last {
      border-bottom: 2px solid #ccc;
    }
  }
  .view-commerce-cart-summary {
    font-size: 1.2em;
  }
  td {
    padding-top: 10px;
  }
  .views-field-line-item-title {
    padding-left: 10px;
  }
  .views-field-quantity {
    text-align: center;
    margin-left: 0;
    width: 100px;
  }
  .views-field-commerce-unit-price {
    text-align: center;
    margin-left: 0;
    width: 100px;
  }
  .views-field-commerce-total {
    text-align: center;
    margin-left: 0;
    width: 100px;
  }
  th {
    padding-right: 0;
  }
  .field-name-commerce-order-total {
    .component-title {
      width: 200px;
      text-align: right;
      padding-bottom: 10px;
      padding-right: 5px;
    }
    .component-total {
      width: 100px;
      text-align: center;
      padding-bottom: 10px;
    }
  }
  label {
    color: #000;
    float: none;
    padding-bottom: 5px;
  }
  .customer_profile_billing {
    padding-left: 20px;
  }
  .customer_profile_shipping {
    padding-left: 20px;
  }
  .checkout-buttons {
    padding-left: 20px;
  }
  .form-type-select .ajax-processed {
    margin-left: 0;
  }
}
#commerce-checkout-form-shipping {
  padding: 20px;
  fieldset {
    border: none;
    margin-bottom: 55px;
  }
  .fieldset-legend {
    font-size: 1.5em;
    font-weight: bold;
  }
}
#commerce-checkout-form-review {
  padding: 20px;
  fieldset {
    border: none;
    margin-bottom: 55px;
  }
  .fieldset-legend {
    font-size: 1.5em;
    font-weight: bold;
  }
  .views-field-quantity {
    text-align: center;
    margin-left: 0;
  }
  .views-field-commerce-unit-price {
    text-align: center;
    margin-left: 0;
    width: 100px;
  }
  .views-field-commerce-total {
    text-align: center;
    margin-left: 0;
    width: 100px;
  }
  .commerce-price-formatted-components .component-total {
    width: 100px;
    text-align: center;
    padding-bottom: 10px;
  }
}
.checkout-help {
  padding-bottom: 20px;
  font-size: 1.2em;
}
#edit-checkout-review {
  margin-bottom: 20px;
  table {
    width: 650px;
    background: none;
    tr {
      background: #fff;
      &.pane-title {
        background: #eee;
      }
      &.pane-data td {
        padding-bottom: 20px;
      }
    }
    td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  #payment-details label {
    float: none;
  }
}
.checkout-completion-message {
  font-size: 1.2em;
  padding: 20px 40px 75px;
}
.image-widget-data {
  .file {
    display: none;
  }
  .file-size {
    display: none;
  }
}
.view-blog {
  .views-row {
    padding: 10px;
    overflow: hidden;
  }
  .node-blog-post {
    padding-bottom: 0;
    h2 {
      margin-bottom: 0;
    }
    .content {
      padding-top: 5px;
    }
    p {
      font-size: 1.1em;
    }
  }
  .field-type-taxonomy-term-reference {
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
    padding-left: 5px;
    color: #555;
    font-size: 1em;
    a {
      color: #999;
    }
  }
  .field-name-field-image {
    float: left;
    width: 300px;
    padding-right: 15px;
    padding-bottom: 0;
    clear: left;
  }
  .taxonomy {
    float: left;
    width: 300px;
    padding-right: 15px;
    padding-bottom: 0;
    clear: left;
  }
  .field-name-body {
    float: right;
    width: 385px;
    clear: right;
    p {
      margin-top: 0;
    }
  }
  .read-more {
    float: right;
    width: 385px;
    clear: right;
    text-align: right;
    border-top: 1px solid #eee;
    padding: 10px;
    width: 365px;
  }
}
.node-blog-post {
  padding: 0;
  padding-bottom: 50px;
  .submitted {
    color: #555;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  p {
    font-size: 1.2em;
    line-height: 1.4;
    margin-bottom: 1.5em;
  }
  .field-type-taxonomy-term-reference {
    font-size: 1.1em;
    padding-bottom: 0.5em;
    overflow: hidden;
  }
}
.node-page {
  line-height: 1.5;
  .field-name-body {
    line-height: 1.5;
    font-size: 1.2em;
    h2 {
      color: #993333;
      margin: 15px 0 5px;
      border-bottom: 1px dashed #ccc;
      a:hover {
        text-decoration: none;
      }
    }
    h3 {
      margin: 5px 0;
    }
    p {
      margin: 0;
      padding: 5px 0 15px;
    }
    ul {
      padding-bottom: 20px;
      margin-top: 5px;
    }
  }
  .red-banner {
    p {
      padding-bottom: 0;
    }
    h3 {
      margin-top: 0;
    }
  }
}
.red-banner {
  color: #fff;
  padding: 25px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 15px 0 15px;
  font-size: 1.1em;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 10px 0 #aaa;
  border: 2px solid #fff;
  margin: 30px 0;
  background: rgba(127,48,43,1);
  background: -moz-linear-gradient(top,rgba(153,51,51,1) 0%,rgba(127,48,43,1) 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(153,51,51,1)),color-stop(100%,rgba(127,48,43,1)));
  background: -webkit-linear-gradient(top,rgba(153,51,51,1) 0%,rgba(127,48,43,1) 100%);
  background: -o-linear-gradient(top,rgba(153,51,51,1) 0%,rgba(127,48,43,1) 100%);
  background: -ms-linear-gradient(top,rgba(153,51,51,1) 0%,rgba(127,48,43,1) 100%);
  background: linear-gradient(top,rgba(153,51,51,1) 0%,rgba(127,48,43,1) 100%);
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 1px 1px 1px #4d0808;
  filter: dropshadow(color=#4d0808, offx=1, offy=1);
  a {
    color: #fff;
    text-decoration: underline;
  }
}
#block-menu-menu-footer-navigation {
  .nolink {
    color: #fff;
    font-size: 1.1em;
  }
  li ul {
    margin-bottom: 15px;
    margin-top: 5px;
  }
}
#block-system-main {
  .view-testimonials {
    padding: 20px;
    font-size: 1.3em;
    .views-row {
      padding-bottom: 1.5em;
      border-bottom: 1px solid #ddd;
      margin-bottom: 2em;
    }
    .name {
      color: #333;
      padding-left: 230px;
      padding-right: 0;
      text-align: right;
      padding-bottom: 0.25em;
    }
    .description {
      color: #333;
      padding-left: 230px;
      padding-right: 0;
      text-align: right;
      font-size: 0.85em;
      font-style: italic;
    }
  }
  #edit-search-api-views-fulltext-wrapper {
    padding: 20px;
  }
}
.view-testimonials {
  font-size: 1.1em;
  .quote {
    padding-bottom: 1.5em;
  }
  .more-link {
    padding-top: 1em;
  }
}
.view-search-products {
  padding: 0;
  .views-field-field-images {
    float: left;
    width: 75px;
    margin-right: 15px;
  }
  h2 {
    margin-bottom: 0.25em;
    margin-top: 0;
  }
  .views-row {
    overflow: hidden;
    padding-bottom: 20px;
  }
  .view-content {
    padding-top: 20px;
    padding: 20px;
  }
}
h2.nice-title {
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 0 -1px 0 #000000;
  filter: dropshadow(color = #000000, offx = 0, offy = -1);
}
h1.nice-title {
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 0 -1px 0 #000000;
  filter: dropshadow(color = #000000, offx = 0, offy = -1);
}
#toolbar ul.menu {
  width: auto;
  background-color: transparent;
  li a {
    height: auto;
    width: auto;
  }
}
.attribute-widgets .form-type-select {
  margin: 5px 0 0;
}
.field-type-image div div.form-item {
  margin: 0 0 10px;
}
.form-item label {
  font-size: 12px;
  color: #5f5f5f;
}
#edit-line-item-fields-field-custom-text-und-0-ajax-wrapper .form-item .description {
  color: #5f5f5f;
  font-weight: 500;
  margin: -24px 0 0 111px;
  float: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  clear: both;
  font-size: 10px;
  line-height: 1.1;
  br {
    display: none;
  }
}
#edit-line-item-fields-field-logo-to-upload-und-0-upload-button.form-submit {
  margin: 10px 0 0;
  float: none;
  margin-top: 5px;
}
#edit-line-item-fields-field-logo-to-upload-und-0-remove-button.form-submit {
  margin: 10px 0 0;
  float: none;
  margin-top: 5px;
}
#edit-line-item-fields-field-custom-text-und-0-upload-button.form-submit {
  margin: 10px 0 0;
  float: none;
  margin-top: 5px;
}
#edit-line-item-fields-field-custom-text-und-0-remove-button.form-submit {
  margin: 10px 0 0;
  float: none;
  margin-top: 5px;
}
#line-item-fields-field-custom-text-add-more-wrapper div.form-item {
  margin: 0;
}
#block-system-main-menu ul {
  overflow: hidden;
  padding-left: 0;
  margin-left: 0;
  li {
    padding-left: 0;
    padding-top: 0;
  }
}
.description p {
  margin-top: 0;
  margin-bottom: 0.5em;
}
.field-name-field-logo-to-upload div div.form-item {
  margin-bottom: 0;
}
#edit_account input {
  margin-left: 0;
}
.commerce-order-handler-area-order-total .commerce-price-formatted-components {
  width: 27%;
}
.view-commerce-cart-block td.price {
  background: none;
}
.read-more a {
  font-weight: bold;
}
ol.search-results {
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 30px;
  li {
    padding: 5px 0;
  }
}
